// import '@fortawesome/fontawesome-free/js/brands.min';
// import '@fortawesome/fontawesome-free/js/regular.min';
// import '@fortawesome/fontawesome-free/js/solid.min';
// import '@fortawesome/fontawesome-free/js/fontawesome.min';
import './style/main.scss';

// import './App';
// import 'popper.js/dist/popper.min';
// import 'bootstrap/dist/js/bootstrap.min';

import './initialization_scripts';
