import WOW from 'wow.js/dist/wow.min';

// For lazy loading 
import lazyload from 'lazyload';

import {init as initNewsletterSubscriptionForm} from "./initNewsletterSubscriptionForm";

document.addEventListener('DOMContentLoaded', function() {
    // Allows developper to control scroll
    if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'manual'
    }
    window.scrollTo(0, 0);

    // Initializes wow.js
    new WOW().init();

    // smooth scrolling to an anchor
    $(document).on('click', 'a.link-to-section[href^="#"]', function (event) {
        event.preventDefault();
    
        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top - $('nav.navbar').outerHeight()
        }, 500);
    });

    // Initialize margin-top on slider to to make the whole image visible
    $('#header-slider').css('margin-top', $('nav.navbar').height());

    // Set background image on services section relative to the hovered service
    $("#graphic-creation-card").hover(function () {
        $(".services-section").css('backgroundImage', 'url(./assets/images/graphic-creation.svg)');
    });

    $("#it-solutions-card").hover(function () {
        $(".services-section").css('backgroundImage', 'url(./assets/images/it-solutions.svg)');
    });

    $("#goodies-production-card").hover(function () {
        $(".services-section").css('backgroundImage', 'url(./assets/images/gift.svg)');
    });

    $("#events-card").hover(function () {
        $(".services-section").css('backgroundImage', 'url(./assets/images/events.svg)');
    });

    $("#surveys-card").hover(function () {
        $(".services-section").css('backgroundImage', 'url(./assets/images/surveys.svg)');
    });

    $("#marketing-card").hover(function () {
        $(".services-section").css('backgroundImage', 'url(./assets/images/marketing.svg)');
    });

    // Starts lazy loading
    new lazyload();

    initNewsletterSubscriptionForm("newsletter-subcription-form");
});
