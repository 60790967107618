"use strict";

/**
 * Ce script initialise l'action du formulaire d'inscription à la newsletter.
 * Au backend on utilise phplist, donc la forme de la requete d'inscription a 
 * été faite pour ce backend.
 * 
 * Usage: il faut adapter les constantes à suivre en fonction de votre installation.
 * - l'adresse du serveur phplist
 * - l'identifiant de la newsletter à laquelle les visiteurs seront inscrits.
 * - le formulaire doit avoir un champs input de type email nommé "email" et un élément
 *      qui servira a afficher le résultat des traitements.
 * - On appel la fonction init() avec l'id du formulaire HTML d'inscription.
 * 
 * @author Youssouf, Pour Archenis. Janvier 2020.
 */

 let devMode = false;
/* Serveur a qui la requette d'inscription sera envoyée (phplist dans notre cas): */
 let TARGET_SERVER;
/* Identifiant de la liste d'information à laquelle l'utilisateur sera inscrit: ex: Nos promotions. */
 let SUBSCRIPTION_LIST_ID;

 if(devMode){
    TARGET_SERVER = "http://localhost:8080";
    SUBSCRIPTION_LIST_ID = 1;
 }
 else {
    TARGET_SERVER = "https://emailing.archenis.com";
    SUBSCRIPTION_LIST_ID = 2; //Une liste qui existe deja dans le serveur phplist.
 }


/* Adresse de notre requete ajax: */
let serverEndpoint = `${TARGET_SERVER}?p=subscribe&id=${SUBSCRIPTION_LIST_ID}&ajaxed=1`;

/* Affiche le résultat des traiements: */
let resultDisplay;

/** Le formulaire */
var subscriptionForm ;

/** Message de succes par defaut: */
const defaultSuccessMsg = "Merci, un email de confirmation a été envoyé sur votre boîte, veuillez le consulter puis <strong>cliquez sur le lien de confirmation</strong> SVP.";
                
/**
 * Initialise les listeners du formulaire d'inscription à la 
 * newsletter.
 * 
 * @param {string} formId Id du formulaire d'inscription
 */
export function init(formId){
    try{
        if(!formId){
            console.error("Veuillez fournir l'id du formulaire");
            return;
        }
        subscriptionForm = document.getElementById(formId);
        if(!subscriptionForm){
            console.error(`Le formulaire ${formId} est introuvable.`);
        }

        subscriptionForm.addEventListener("submit", onFormSubmit);


        resultDisplay = document.getElementById("newsletter-subcription-form-result");
        if(resultDisplay)
            resultDisplay.style.visibility = "hidden";
    } catch(error){
        console.error("Subscription form init error:", error);
    }
}

function displayOkMessage(msg){
    resultDisplay.innerHTML = msg;
    resultDisplay.classList.value = "alert alert-success m-2";
    resultDisplay.style.visibility = "visible";
}

function displayErrorMessage(msg){
    resultDisplay.innerHTML = msg;
    resultDisplay.classList.value = "alert alert-danger m-2";
    resultDisplay.style.visibility = "visible";
}

function resetDisplay(){
    resultDisplay.style.visibility = "hidden";
    resultDisplay.innerHTML = "";
    resultDisplay.classList.value = "";

}

function displayText(msg){
    resultDisplay.innerHTML = msg;
    resultDisplay.classList.value = "alert alert-default m-2 text-muted";
    resultDisplay.style.visibility = "visible";
}

/** Timeout handler utilisé pour effacer les messages affichés à destination de l'utilisateur. */
var lastTimeout;

/** Listener appelé au début de la soumission du formulaire. */
function onStartProcessing(){
    resetDisplay();
    displayText("Enregistrement...");
    if(lastTimeout)
        clearTimeout(lastTimeout);
}

function onFormSubmit(e){    
    e.preventDefault();
    let formData = new FormData(e.target);

    //On ajoute des paramétres auxquels le script phplist s'attend à recevoir:
    formData.append("htmlemail", "1");
    formData.append("VerificationCodeX", "");
    formData.append("subscribe", "blabla bla"); //Titre du bouton de soumission du formulaire, c'est pas important.
    formData.append("list[2]", "signup"); //? Je ne sais pas à quoi ca sert, mais c'est envoyé par le form html.
    formData.append("listname[2]", "newsletter"); //? Le nom de la liste où on veut s'inscrire peut-être.
    
    if(formData.get("email").length == 0){
        return displayErrorMessage("Pour être informé de nos offres et promos, inscrivez votre adresse email");
    }

    try{
        onStartProcessing();

        if (window.XMLHttpRequest) { // Mozilla, Safari, IE7+...
            let httpRequest = new XMLHttpRequest();
    
            httpRequest.onreadystatechange = handleServerResponse;
            httpRequest.open("post", serverEndpoint);
            httpRequest.setRequestHeader("X-Requested-With", "XMLHttpRequest");
            httpRequest.setRequestHeader("Cache-Control", "no-cache");
            httpRequest.send(formData);
        }
  
    } catch(e){
        let message = "L'enregistrement rencontre une erreur, veuillez reéssayer ultérieurement SVP.";
        displayErrorMessage(message); 
      }
  }


  function handleServerResponse(readyState){
    try{
        let response = readyState.target;
        if(response.readyState === XMLHttpRequest.DONE){
            if(response.status === 200){
                let parsedResponse = parseServerResponse(response);
                parsedResponse.success ? onSubmissionSuccessful(parsedResponse.data) : onSubmissionFailed(parsedResponse.data);
            }
            else{
                onSubmissionFailed("Ce service est indisponible actuellement. Veuillez reéssayer ultérieurement ou si l'erreur persiste, inscrivez-vous sur https://emailing.archenis.com [status:" + response.status +"]");
            }
        }

    }catch(error){
        console.error("error:", error);
        onSubmissionFailed("Ce service est indisponible actuellement. Veuillez reéssayer ultérieurement ou si l'erreur persiste, écrivez un mail à postmaster@archenis.com SVP [excpt]");
    }
  }

  function onSubmissionFailed(error){
      console.error("err:", error);
      displayErrorMessage(error);
      if(lastTimeout)
        clearTimeout(lastTimeout);
      lastTimeout = setTimeout(resetDisplay, 10000);
  }

  function onSubmissionSuccessful(msg){
      displayOkMessage(msg);
      if(lastTimeout)
        clearTimeout(lastTimeout);
      lastTimeout = setTimeout(resetDisplay, 15000);
      subscriptionForm ? subscriptionForm.reset() : void(0);
  }


  /**
   * Parses server response.
   * @param response {object} XHR response
   */
  function parseServerResponse(response){
    let responseText = response.responseText;
    let parsedResponse = {success: false, data: ""};
    if(response.status == 404 )
        parseServerResponse.data = "Ce service est indisponible actuellement.";

    else if(response.status >= 400 && response.status != 404 && response.status < 500){
        parseServerResponse.data = "[status: " + response.status + "].";
    }
    else if(response.status == 500)
        parseServerResponse.data = "Le serveur est actuellement indisponible. Si cela persiste veuillez nous en informer en écrivant à postmaster@archenis.com SVP.";

    if(response.status == 200){
        // On vérifi si le texte de réussite est présent dans la réponse:
        let successTexts = ["Thank you", "Merci"];
        for(let i = 0; i < successTexts.length; ++i){
            let success = successTexts[i];
            let rx = new RegExp(success, "i");
            if(rx.test(responseText)){
                parsedResponse.success = true;
                parsedResponse.data = defaultSuccessMsg;
                break;
            }
        }
        if(!parsedResponse.success){
            parsedResponse.data = defaultSuccessMsg; //default msg if we couldn't tell what the server responded.
        }
    }
    return parsedResponse;
  }